/**
 * @desc Tutorial for e-Editions.
 */
(function(window, document, $, __tnt) {

    var me = __tnt.eedition || (__tnt.eedition = {}),
        initDisplay = false,
        steps = [],
        stepsArray = {},
        subscription = false,
        windowWidth = $(window).width();

    me.intro = {

        // Initialize tutorial
        init: function(bypassCookie) {

            // Subscription Check
            if ($('#eedition-subscription').length && !$('#eedition-subscription').hasClass('hide')) {
                subscription = true;
            }
            if (!subscription) {
                if (windowWidth <= 320 ) {
                    $('#help-overlay-menu').remove();
                } else {
                    var displayTutorial = false,
                        settings = {},
                        pageUuid = $('*[data-eedition]').data('eedition-uuid');
                    if (bypassCookie) {
                        settings.init = true;
                    } else {

                        // Cookies Enabled
                        if (__tnt.client.capabilities.supportsCookies) {

                            // Cookie Check
                            if (me.intro.cookie('verify')) {
                                settings = me.intro.cookie('data');
                                me.intro.cookie('update', null);
                            } else {
                                me.intro.cookie('create', {'buy_btn':false, 'disable':false, 'view_toggle':false, 'zoom':false});
                                settings = me.intro.cookie('data');
                                settings.init = true;
                            }

                            // Display Check
                            if (settings.init || (settings.disable === false && ((settings.buy_btn === false && $('#eedition-buy-page').length && $('#eedition-buy-page').is(':visible')) || (settings.view_toggle === false && $('.eedition-switch').length) || (settings.zoom === false && $('.eedition-zoom').is(':visible'))))) {
                                displayTutorial = true;
                            }
                        }
                    }

                    if (bypassCookie || displayTutorial) {
                        if (!initDisplay) {

                            // Craft tutorial for view mode
                            if (me.utilities.viewMode(pageUuid) == 'image') {
                                me.intro.pageView(settings);
                            } else if (me.utilities.viewMode(pageUuid) == 'text'){
                                me.intro.textView(settings);
                            }
                        }
                        if (!$.isEmptyObject(stepsArray)) {
                            var tutorial = introJs();
                            initDisplay = true;

                            // IntroJS options
                            tutorial.setOptions({'showStepNumbers': false, 'showProgress': true, 'keyboardNavigation': false});

                            // IntroJS API
                            tutorial.onbeforechange(function(e) {
                                me.intro.beforeChange(e);
                            });
                            tutorial.onafterchange(function(e) {
                                me.intro.afterChange(e);
                            });
                            tutorial.onchange(function(e) {
                                me.intro.onChange(e);
                            });
                            tutorial.onexit(function() {
                                me.intro.onExit();
                            });

                            // Define steps and start
                            tutorial.setOptions(stepsArray).start();
                        }
                    }
                }
            }
        },

        // Page view
        pageView: function(settings) {
            if (settings.init) {
                steps.push(me.intro.navigation());
            }
            if ($('#eedition-buy-page').length && $('#eedition-buy-page').is(':visible') && (settings.init || settings.buy_btn === false)) {
                steps.push(me.intro.buyButton());
            }
            if ($('.eedition-switch').length && $('.eedition-switch').is(':visible') && (settings.init || settings.view_toggle === false)) {
                steps.push(me.intro.viewToggle());
            }
            if ($('.eedition-zoom').is(':visible') && (settings.init || settings.zoom === false)) {
                steps.push(me.intro.zoom());
            }
            if (settings.init) {
                if ($('.sidebar-icon.editions').length) {
                    steps.push(me.intro.editions());
                }
                steps.push(me.intro.downloads());
            }
            stepsArray.steps = steps;
        },

        // Text view
        textView: function(settings) {
            if (settings.init) {
                steps.push(me.intro.navigation());
            }
            if ($('#eedition-buy-page').length && $('#eedition-buy-page').is(':visible') && (settings.init || settings.buy_btn === false)) {
                steps.push(me.intro.buyButton());
            }
            if ($('.eedition-switch').length && (settings.init || settings.view_toggle === false)) {
                steps.push(me.intro.viewToggle());
            }
            if ($('.eedition-text-size').length && settings.init) {
                steps.push(me.intro.textSize());
            }
            if (settings.init) {
                if ($('.sidebar-icon.editions').length) {
                    steps.push(me.intro.editions());
                }
                steps.push(me.intro.downloads());
            }
            stepsArray.steps = steps;
        },

        // Cookie controller
        cookie: function(action, value) {
            var tutorialCookie = Cookies.get('__tnt_eedition_tutorial'),
                parsedObj;
            if (action == 'create') {
                Cookies.set('__tnt_eedition_tutorial', JSON.stringify(value), { expires: 365, path: '/' });
            } else if (action == 'data') {
                parsedObj = JSON.parse(tutorialCookie);
                return parsedObj;
            } else if (action == 'update') {
                if (value == null) {
                    Cookies.set('__tnt_eedition_tutorial', tutorialCookie, { expires: 365, path: '/' });
                } else {
                    parsedObj = JSON.parse(tutorialCookie);
                    var update = $.extend(parsedObj, value);
                    Cookies.set('__tnt_eedition_tutorial', JSON.stringify(update), { expires: 365, path: '/' });
                }
            } else if (action == 'verify') {
                var cookieFound = (tutorialCookie == null) ? false : true;
                return cookieFound;
            }
        },

        // After change 
        afterChange: function(e) {
            if (!me.browser.isIe11()) {
                if ($(e).attr('id') == 'intro-editions') {
                    if ($('html').hasClass('tnt-svg-active')) {
                        $('.introjs-helperLayer').html('<div class="introjs-sidebar-placeholder"><i class="fas tnt-newspaper tnt-2x"></i><p class="hidden-xs">Editions</p></div>');
                    } else {
                        $('.introjs-helperLayer').html('<div class="introjs-sidebar-placeholder"><i class="fas tnt-newspaper fa-2x"></i><p class="hidden-xs">Editions</p></div>');
                    }
                }
                if ($(e).attr('id') == 'intro-downloads') {
                    if ($('html').hasClass('tnt-svg-active')) {
                        $('.introjs-helperLayer').html('<div class="introjs-sidebar-placeholder"><i class="fas tnt-cloud-download-alt tnt-2x"></i><p class="hidden-xs">Downloads</p></div>');
                    } else {
                        $('.introjs-helperLayer').html('<div class="introjs-sidebar-placeholder"><i class="fas tnt-cloud-download-alt fa-2x"></i><p class="hidden-xs">Downloads</p></div>');
                    }
                }
            }
            if ($(e).hasClass('introjsFloatingElement')) {
                $('.eedition-bottombar-btn').click();
            }
            if (windowWidth < 500) {
                if ($(e).attr('id') == 'eedition-buy-page' || $(e).hasClass('eedition-switch')) {
                    setTimeout(function(){
                        $('.introjs-tooltipReferenceLayer').removeAttr('style').css({top:'50%',left:'50%'});
                    }, 100)
                }
            }
        },

        // Before change
        beforeChange: function(e) {
            var settings = me.intro.cookie('data');
            if (__tnt.client.capabilities.supportsCookies) {
                if ($(e).attr('id') == 'eedition-buy-page' && settings.buy_btn !== true) {
                    me.intro.cookie('update', {'buy_btn':true});
                } else if ($(e).hasClass('eedition-switch') && settings.view_toggle !== true) {
                    me.intro.cookie('update', {'view_toggle':true});
                } else if ($(e).hasClass('eedition-zoom-btns') && settings.zoom !== true) {
                    me.intro.cookie('update', {'zoom':true});
                }
            }
            if ($('#eedition-mainmenu').hasClass('active') || $('.eedition-bottombar').hasClass('active')) {
                me.menu.reset();
            }
            if (!$('.introjs-helperLayer').is(':empty')) {
                $('.introjs-helperLayer').empty();
            }
        },

        // On change
        onChange: function(e) {
            if ($(e).attr('id') == 'intro-editions' || $(e).attr('id') == 'intro-downloads') {
                $('#eedition-menu-icon').click();
            }
        },

        // On exit
        onExit: function() {
            var settings = me.intro.cookie('data');
            if (__tnt.client.capabilities.supportsCookies) {
                if (settings.disable !== true && settings.buy_btn === true && settings.view_toggle === true) {
                    me.intro.cookie('update', {'disable':true});
                }
                if ($('#eedition-buy-page').length && $('#eedition-buy-page').is(':visible') && settings.buy_btn !== true) {
                    me.intro.cookie('update', {'buy_btn':true});
                }
                if ($('.eedition-switch').length && settings.view_toggle !== true) {
                    me.intro.cookie('update', {'view_toggle':true});
                }
                if ($('.eedition-zoom').is(':visible') && settings.zoom !== true) {
                    me.intro.cookie('update', {'zoom':true});
                }
            }
            if ($('.eedition-sidebar').hasClass('active')  || $('.eedition-bottombar').hasClass('active')){
                me.menu.reset();
            }
        },

        // Buy button
        buyButton: function() {
            return {
                element: '#eedition-buy-page',
                intro: '<span>Buy Page Button</span><p>Order a print of the current page. Choose from a variety of exciting options.</p>'
            };
        },

        // Downloads menu
        downloads: function() {
            return {
                element: '#intro-downloads',
                intro: '<span>Downloads</span><p>Download the current page or the entire edition for offline reading. (Requires an app that can open and view PDF files. If you don&#39;t have a PDF viewer, check out the app store for your device.)</p>'
            };
        },

        // Editions menu
        editions: function() {
            return {
                element: '#intro-editions',
                intro: '<span>Editions</span><p>Access the most recent editions of this publication.</p>'
            };
        },

        // Navigation
        navigation: function() {
            return {
                intro: '<span>Page to Page Navigation</span><p>Use the arrows at the right and left of the screen to turn the page. You can also swipe from page to page, use the arrows on your keyboard, or jump directly to a specific page using the menu at the bottom of the screen.</p>'
            };
        },
        
        // Zoom
        zoom: function() {
            return {
                element: '.eedition-zoom-btns',
                intro: '<span>Zoom</span><p>Zoom in or out of the page as needed for optimal reading comfort.</p>'
            };
        },

        // Text size
        textSize: function() {
            return {
                element: '.eedition-text-size',
                intro: '<span>Text Size</span><p>Change the text size. (To use this setting, you must select “Text” from the “Text/Page” toggle.)</p>'
            };
        },

        // Text/Page toggle
        viewToggle: function() {
            return {
                element: '.eedition-switch',
                intro: '<span>Text / Page Toggle</span><p>Toggle between "Text" and "Page" views. The "Text" view may include supplemental information, like videos, additional images, or even longer versions of articles. The "Page" view is a digital replica of the original printed page.</p>'
            };
        }

    };

})(window, document, jQuery, __tnt);